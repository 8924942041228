import React, {useMemo} from "react";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { useLocation } from '@reach/router';

const SiteMetadata = ({title, description, keywords, lang }) => {
 
  const url = useLocation();
  const finalUrl = url.pathname.split('/')


  const schemaOrgJSONLDArticle = [
    {
      "@context": "http://schema.org",
      "@type": "Article",
      "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": url
      },
      "dateCreated": "дата",
      "datePublished": "дата",
      "dateModified": "дата",
      "url": url,
      "headline": title,
      "name": title,
      "articleBody": "артикъл боди",
                  "author": {
          "@type": "Organization",
          "name": "KT International",
          "url": "https://ktinternational.eu/",
          "sameAs": [
 "https://bg.linkedin.com/company/ktinternational",
"https://www.facebook.com/ktinternational.eu"
          ]
      },
                  "articleSection": ["Article"],
      "image": {
          "@type": "ImageObject",
          "url": "URL-то към изображението",
              "width": '381',
              "height": '381'
              },
      "publisher": {
          "@type": "Organization",
          "name": "KT International",
          "url": "https://ktinternational.eu",
          "logo": {
          "@type": "ImageObject",
          "url": "https://ktiinternatil.eu/static/dc3b96a13f257874ab290d29751c4c5e/bb60e/kti-logo.webp"
          },
          "sameAs": [
 "https://bg.linkedin.com/company/ktinternational",
"https://www.facebook.com/ktinternational.eu"
          ]
      }
      
      },
      {
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
          "@type": "ListItem",
          "position": 1,
          "item":
                   {
                    "@id": "https://ktinternational.eu",
                    "name": "KT International"
                    }
          },
                      {
                  "@type": "ListItem",
                  "position": 2,
                  "item":
                     {
                      "@id": "https://ktinternational.eu/get-in-touch",
                    "name": title
                   }
          }
                  ]
        }
  ]

  const schemaOrgJSONLDGetInTouch = [
    {
      "@context": "https://schema.org",
      "@graph": [
        {
        "@type": "Organization",
        "@id": "https://ktinternational.eu/#organization",
        "name": "KT International",
        "url": "https://ktinternational.eu/",
        "sameAs": [
        "https://bg.linkedin.com/company/ktinternational",
        "https://www.facebook.com/ktinternational.eu",
        ],
        "logo": {
          "@type": "ImageObject",
          "@id": "https://ktinternational.eu/#logo",
          "inLanguage": "en-GB",
          "url": "https://ktiinternatil.eu//static/b734232301028ffb06bf071daa841049/3f068/kti-logo-white.webp",
          "contentUrl": "https://ktiinternatil.eu//static/b734232301028ffb06bf071daa841049/3f068/kti-logo-white.webp",
          "width": '150',
          "height": '63',
          "caption": "KT International"
        },
        "image": {
          "@id": "https://ktinternational.eu/#logo"
        }
        },
        {
        "@type": "WebSite",
        "@id": "https://ktinternational.eu/#website",
        "url": url,
        "name": title,
        "description": description,
        "publisher": {
          "@id": "https://ktinternational.eu/#organization"
        },
        "potentialAction": [
          {
          "@type": "SearchAction",
          "target": {
            "@type": "EntryPoint",
            "urlTemplate": "https://ktinternational.eu/?s={search_term_string}"
          },
          "query-input": "required name=search_term_string"
          }
        ],
        "inLanguage": "en-GB"
        },
        {
        "@type": "ImageObject",
        "@id": "https://ktinternational.eu/#primaryimage",
        "inLanguage": "en-GB",
        "url": "URL-то към основното изображение на страницата",
        "contentUrl": "URL-то към основното изображение на страницата",
        "width": '300',
        "height": '300',
        "caption": "KT International"
        },
        {
        "@type": [
          "WebPage",
          "ContactPage"
        ],
        "@id": "https://ktinternational.eu/get-in-touch/#primaryimage/#webpage",
        "url": "https://ktinternational.eu/get-in-touch/#primaryimage",
        "name": title,
        "isPartOf": {
          "@id": "https://ktinternational.eu/#website"
        },
        "primaryImageOfPage": {
          "@id": "https://ktinternational.eu/get-in-touch/#primaryimage"
        },
        "datePublished": "дата",
        "dateModified": "дата",
        "breadcrumb": {
          "@id": "https://ktinternational.eu/get-in-touch/#breadcrumb"
        },
        "inLanguage": "en-GB",
        "potentialAction": [
          {
          "@type": "ReadAction",
          "target": [
            "https://ktinternational.eu/get-in-touch"
          ]
          }
        ]
        },
        {
        "@type": "BreadcrumbList",
        "@id": "https://ktinternational.eu/get-in-touch/#breadcrumb",
        "itemListElement": [
          {
          "@type": "ListItem",
          "position": 1,
          "item":
                   {
                    "@id": "https://ktinternational.eu",
                    "name": "KT International"
                    }
          },
                      {
                  "@type": "ListItem",
                  "position": 2,
                  "item":
                     {
                      "@id": "https://ktinternational.eu/get-in-touch",
                    "name": title
                   }
          }
                  ]
        }
      ]
      }
      
  ]

  const schemaOrgJSONLDOurCompany = [

    {
      "@context": "https://schema.org",
      "@graph": [
        {
        "@type": "Organization",
        "@id": "https://ktinternational.eu/#organization",
        "name": "KT International",
        "url": "https://ktinternational.eu/",
        "sameAs": [
        "https://bg.linkedin.com/company/ktinternational",
        "https://www.facebook.com/ktinternational.eu",
        ],
        "logo": {
          "@type": "ImageObject",
          "@id": "https://ktinternational.eu/#logo",
          "inLanguage": "en-GB",
          "url": "https://ktiinternatil.eu/static/dc3b96a13f257874ab290d29751c4c5e/bb60e/kti-logo.webp",
          "contentUrl": "https://ktiinternatil.eu/static/dc3b96a13f257874ab290d29751c4c5e/bb60e/kti-logo.webp",
          "width": '150',
          "height": '63',
          "caption": "KT International"
        },
        "image": {
          "@id": "https://ktinternational.eu/#logo"
        }
        },
        {
        "@type": "WebSite",
        "@id": "https://ktinternational.eu/#website",
        "url": "https://ktinternational.eu/",
        "name": title,
        "description": description,
        "publisher": {
          "@id": "https://ktinternational.eu/#organization"
        },
        "potentialAction": [
          {
          "@type": "SearchAction",
          "target": {
            "@type": "EntryPoint",
            "urlTemplate": "https://ktinternational.eu/?s={search_term_string}"
          },
          "query-input": "required name=search_term_string"
          }
        ],
        "inLanguage": "en-GB"
        },
        {
        "@type": "ImageObject",
        "@id": "https://ktinternational.eu/#primaryimage",
        "inLanguage": "en-GB",
        "url": "URL-то към основното изображение на страницата",
        "contentUrl": "URL-то към основното изображение на страницата",
        "width": '',
        "height": '',
        "caption": "KT International"
        },
        {
        "@type": [
          "WebPage"
                          ,
                    "AboutPage"
                ],
                "@id": "https://ktinternational.eu/our-company/#primaryimage/#webpage",
        "url": "https://ktinternational.eu/our-company/#primaryimage",
        "name": title,
        "isPartOf": {
          "@id": "https://ktinternational.eu/#website"
        },
        "primaryImageOfPage": {
          "@id": "https://ktinternational.eu/our-company/#primaryimage"
        },
        "datePublished": "дата",
        "dateModified": "дата",
        "breadcrumb": {
          "@id": "https://ktinternational.eu/our-company/#breadcrumb"
        },
        "inLanguage": "en-GB",
        "potentialAction": [
          {
          "@type": "ReadAction",
          "target": [
            "https://ktinternational.eu/our-company"
          ]
          }
        ]
        },
        {
        "@type": "BreadcrumbList",
        "@id": "https://ktinternational.eu/our-company/#breadcrumb",
        "itemListElement": [
          {
          "@type": "ListItem",
          "position": 1,
          "item":
                   {
                    "@id": "https://ktinternational.eu",
                    "name": "KT International"
                    }
          },
                      {
                  "@type": "ListItem",
                  "position": 2,
                  "item":
                     {
                      "@id": "https://ktinternational.eu/our-company",
                    "name": title
                   }
          }
                  ]
        }
      ]
      }

  ]
  const schemaOrgJSONLDElse = [

    {
      "@context": "https://schema.org",
      "@graph": [
        {
        "@type": "Organization",
        "@id": "https://ktinternational.eu/#organization",
        "name": "KT International",
        "url": "https://ktinternational.eu/",
        "sameAs": [
        "https://bg.linkedin.com/company/ktinternational",
        "https://www.facebook.com/ktinternational.eu",
        ],
        "logo": {
          "@type": "ImageObject",
          "@id": "https://ktinternational.eu/#logo",
          "inLanguage": "en-GB",
          "url": "https://ktiinternatil.eu/static/dc3b96a13f257874ab290d29751c4c5e/bb60e/kti-logo.webp",
          "contentUrl": "https://ktiinternatil.eu/static/dc3b96a13f257874ab290d29751c4c5e/bb60e/kti-logo.webp",
          "width": '150',
          "height": '63',
          "caption": "KT International"
        },
        "image": {
          "@id": "https://ktinternational.eu/#logo"
        }
        },
        {
        "@type": "WebSite",
        "@id": "https://ktinternational.eu/#website",
        "url": url,
        "name": title,
        "description": description,
        "publisher": {
          "@id": "https://ktinternational.eu/#organization"
        },
        "potentialAction": [
          {
          "@type": "SearchAction",
          "target": {
            "@type": "EntryPoint",
            "urlTemplate": "https://ktinternational.eu/?s={search_term_string}"
          },
          "query-input": "required name=search_term_string"
          }
        ],
        "inLanguage": "en-GB",
        "url" : url
        },
        {
        "@type": "ImageObject",
        "@id": "https://ktinternational.eu/#primaryimage",
        "inLanguage": "en-GB",
        "url": "URL-то към основното изображение на страницата",
        "contentUrl": "URL-то към основното изображение на страницата",
        "width": '',
        "height": '',
        "caption": "KT International"
        },
        {
        "@type": [
          "WebPage"
                      ],
                "@id": "https://ktinternational.eu/#primaryimage/#webpage",
        "url": "https://ktinternational.eu/#primaryimage",
        "name": title,
        "isPartOf": {
          "@id": "https://ktinternational.eu/#website"
        },
        "primaryImageOfPage": {
          "@id": "https://ktinternational.eu/#primaryimage"
        },
        "datePublished": "",
        "dateModified": "",
        "breadcrumb": {
          "@id": "https://ktinternational.eu/#breadcrumb"
        },
        "inLanguage": "en-GB",
        "potentialAction": [
          {
          "@type": "ReadAction",
          "target": [
            "https://ktinternational.eu"
          ],
          "url": url,
          }
        ]
        },
        {
        "@type": "BreadcrumbList",
        "@id": "https://ktinternational.eu/#breadcrumb",
        "itemListElement": [
          {
          "@type": "ListItem",
          "position": 1,
          "item":
                   {
                    "@id": "https://ktinternational.eu",
                    "name": "KT International"
                    }
          },
                      {
                  "@type": "ListItem",
                  "position": 2,
                  "item":
                     {
                      "@id": "https://ktinternational.eu",
                    "name": title
                   }
          }
                  ]
        }
      ]
      }


  ];

  const schemaOrgJSONLDSecond = [
    {
      "@context": "http://schema.org",
      "@type": "Organization",
      "address": {
          "@type": "PostalAddress",
          "addressLocality": "Plovdiv, Bulgaria",
          "postalCode": "4003",
          "streetAddress": "1, Rogoshko Shosse Str."
        },
      "@id": "https://ktinternational.eu/#organization",
      "url": "https://ktinternational.eu/",
      "name": "KT International",
      "logo": {
          "@type": "ImageObject",
          "@id": "https://ktinternational.eu/#logo",
          "inLanguage": "en-GB",
          "url": "https://ktiinternatil.eu/static/dc3b96a13f257874ab290d29751c4c5e/bb60e/kti-logo.webp",
          "contentUrl": "https://ktiinternatil.eu/static/dc3b96a13f257874ab290d29751c4c5e/bb60e/kti-logo.webp",
          "width": '150',
          "height": '63',
          "caption": "KT International"
        },
      "sameAs": [
     "https://bg.linkedin.com/company/ktinternational",
"https://www.facebook.com/ktinternational.eu",
      ],
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+359 32 623 449",
        "email": "office@ktinternational.eu",
        "faxNumber": "+359 32 626 848",
        "contactType": "customer support",
        "areaServed": [
         "BG"
        ]
      }
    }
  ]
  

    if(finalUrl[1] === 'article'){
      var json = JSON.stringify(schemaOrgJSONLDArticle);
      var jsonSecond = JSON.stringify(schemaOrgJSONLDSecond)
    }else if ( finalUrl[1] === 'get-in-touch'){
      var json = JSON.stringify(schemaOrgJSONLDGetInTouch);
      var jsonSecond = JSON.stringify(schemaOrgJSONLDSecond)
    }else if ( finalUrl[1] === 'our-company'){
      var json = JSON.stringify(schemaOrgJSONLDOurCompany);
      var jsonSecond = JSON.stringify(schemaOrgJSONLDSecond)
    }else{
      var json = JSON.stringify(schemaOrgJSONLDElse);
      var jsonSecond = JSON.stringify(schemaOrgJSONLDSecond)
    }

    const first = useMemo(() => json);
    const second = useMemo(() => jsonSecond);
  // const [proba, setProba] = useState('')
  // const [second, setSecond] = useState('')
  // useEffect(() => {

  //   setProba(json)
  //   setSecond(jsonSecond)
  //   // console.log(url)
  //   // setUrl(typeof window !== 'undefined' ? window.location.href : '')

  // }, [proba, second]);

  const {
    site_title: { option_value: siteTitle },
    site_url: { option_value: siteUrl },
  } = useStaticQuery(graphql`
    query SiteMetadata {
      site_title: strapiOption(option_id: { eq: "site_title" }) {
        option_value
      }
      site_url: strapiOption(option_id: { eq: "site_url" }) {
        option_value
      }
    }
  `);
  

  return (
    
    <Helmet
    
      defer={false}
      htmlAttributes={{
        lang,
      }}
      title={title}
      defaultTitle={siteTitle}
      titleTemplate={`%s | ${siteTitle}`}
      
      meta={[
        {
          name: "description",
          content: description,
        },
        {
          name: "keywords",
          content: keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: "KT International",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ]}
    >
       <script type="application/ld+json">
         {first}
  </script>
  <script type="application/ld+json">
         {second}
  </script>
  
      <html lang="en" />
      <link rel="canonical" href={`${url.href}`} />
      {/* <link
        rel="preload"
        as="font"
        href="/fonts/IdealistSansLight.woff2"
        type="font/woff2"
        crossOrigin
      /> */}
      {/* <link
        rel="preload"
        as="font"
        href="/fonts/IdealistSansLight.woff"
        type="font/woff"
        crossOrigin="anonymous"
      /> */}
      <meta name="docsearch:version" content="2.0" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Oswald:300,600&display=block"
        rel="stylesheet"
      ></link>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#00aba9" />
      <meta name="theme-color" content="#1c1c1c" />
      <meta name="msapplication-navbutton-color" content="#1c1c1c" />
      <meta name="apple-mobile-web-app-status-bar-style" content="#1c1c1c" />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en-GB" />
      <meta property="og:site_name" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content='https://ktiinternatil.eu/static/dc3b96a13f257874ab290d29751c4c5e/bb60e/kti-logo.webp' />
      <meta property="og:image:width" content="150" />
      <meta property="og:image:height" content="63" />

      <meta name="twitter:card" content="summary" />
      {/* <meta name="twitter:site" content={twitter} /> */}
      {
        <script
          src="https://www.google.com/recaptcha/api.js?render=explicit"
          async
          defer
          
        />
      }
      {
      <script type="application/ld+json">
      {
     
      }
   </script>}
      {/* {pathname.indexOf("get-in-touch") > -1 ? (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `var onloadCallback = function() {
          if (window.grecaptcha) {
            if (document.querySelector('#g-recaptcha-contact-form')) {
              const widgetContactForm = grecaptcha.render('g-recaptcha-contact-form', {
                'sitekey': '${process.env.G_CAPTCHA_SITE_KEY}'
              })
            }
            if (document.querySelector('#g-recaptcha-application-form')) {
              const widgetApplicationForm = grecaptcha.render('g-recaptcha-application-form', {
                'sitekey': '${process.env.G_CAPTCHA_SITE_KEY}'
              })
            }
          }
        }`,
          }}
        />
      ) : (
        ""
      )}
      {pathname.indexOf("get-in-touch") > -1 ? (
        <script
          src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
          async
          defer
        />
      ) : (
        ""
      )} */}
      {/* {pathname.indexOf("get-in-touch") > -1 ? (
        <script
          src="https://www.google.com/recaptcha/api.js?onload=CaptchaCallback&render=explicit"
          async
          defer
        ></script>
      ) : (
        ""
      )}

      <script type="text/javascript">
        {`
         const CaptchaCallback = () => {
            if (window.grecaptcha) {
              [...document.querySelectorAll('.g-recaptcha')].forEach( el => {grecaptcha.render(el, {'sitekey': '${process.env.G_CAPTCHA_SITE_KEY}'})})
            }
          }`}
      </script> */}
      {/* {pathname.indexOf("get-in-touch") > -1 ? (
        <script
          src="https://www.google.com/recaptcha/api.js"
          async
          defer
        ></script>
      ) : (
        ""
      )} */}
    </Helmet>
  );
};

export default SiteMetadata;




     
      