import _ from "lodash";
import { ModalRoutingContext } from "./src/context/modal-routing";
import Layout from "./src/layouts/index";
import { navigate } from "gatsby";
import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";

const modalProps = {
  style: {
    overlay: {
      position: `fixed`,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: `rgba(0, 0, 0, 0.75)`,
      zIndex: 999,
    },
    content: {
      top: "initial",
      left: "initial",
      right: "initial",
      bottom: "initial",
      border: "initial",
      overflow: "hidden",
      borderRadius: "initial",
      padding: "initial",
    },
  },
  contentLabel: `Modal`,
  bodyOpenClassName: "ReactModal__Body--open",
  htmlOpenClassName: "ReactModal__Html--open",
  overlayClassName: "ReactModal__Overlay",
};

export const NewPageWrap = ({ passProps, passElement }) => {
  // console.log(passProps, passElement, random);

  const { location } = passProps;
  const isModal = passProps && _.get(location, "state.modal");
  const isClosingModal = passProps && _.get(location, "state.closingModal");
  const isCloseOnOverlayClick = passProps && _.get(location, "state.closeOnOverlayClick");
  const overlayClassName = passProps && _.get(location, "state.overlayClassName");

  const modalFinalProps = { ...modalProps, ...{ overlayClassName: overlayClassName || modalProps.overlayClassName } }

  const [transKey, setTransKey] = useState(location.key);
  const renderedElement = useRef(passElement);
  // const [renderedElement, setRenderedElement] = useState(passElement);
  const didMount = useRef(false);
  const currentLocation = useRef(
    typeof window !== "undefined"
      ? {
        pathname: window.location.pathname,
        hash: window.location.hash,
      }
      : {}
  );
  useEffect(() => {
    if (isModal) {
      return;
    }
    currentLocation.current = {
      pathname: window.location.pathname,
      hash: window.location.hash,
    };
  });

  if (transKey !== location.pathname) {
    // console.log(isModal, isClosingModal, didMount.current);

    if (!isModal && !isClosingModal) {
      setTransKey(location.pathname);
      renderedElement.current = passElement;
      // setRenderedElement(passElement);
    } else if (!isModal && isClosingModal && !didMount.current) {
      didMount.current = true;
      // setRenderedElement(passElement);
    }
  }

  const handleRequestClose = () => {
    navigate(currentLocation.current.pathname + currentLocation.current.hash, {
      state: {
        closingModal: true,
        scrollPosition: window.pageYOffset,
      },
    });
  };

  return (
    <Layout transKey={transKey} {...passProps}>
      {renderedElement.current}
      <Modal
        onRequestClose={handleRequestClose}
        isOpen={!!isModal}
        shouldCloseOnOverlayClick={isCloseOnOverlayClick}
        {...modalFinalProps}
      >
        <ModalRoutingContext.Provider
          value={{
            modal: true,
            closeTo:
              currentLocation.current.pathname + currentLocation.current.hash,
          }}
        >
          {passElement}
        </ModalRoutingContext.Provider>
      </Modal>
    </Layout>
  );
};
