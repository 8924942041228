import Modal from "react-modal";
import React from "react";
import { NewPageWrap } from "./wrapPageElement";

const getTargetOffset = (hash) => {
  const id = window.decodeURI(hash.replace(`#`, ``));
  const offsetY = window.innerWidth < 600 ? 77 : 83;
  if (id !== ``) {
    const element = document.getElementById(id);
    if (element) {
      // let scrollTop =
      //   window.pageYOffset ||
      //   document.documentElement.scrollTop ||
      //   document.body.scrollTop;
      // let clientTop =
      //   document.documentElement.clientTop || document.body.clientTop || 0;
      // let computedStyles = window.getComputedStyle(element);
      // let scrollMarginTop =
      //   computedStyles.getPropertyValue(`scroll-margin-top`) ||
      //   computedStyles.getPropertyValue(`scroll-snap-margin-top`) ||
      //   `0px`;
      // console.log(
      //   element.getBoundingClientRect().top,
      //   scrollTop,
      //   parseInt(scrollMarginTop, 10),
      //   clientTop,
      //   offsetY
      // );
      // console.log(element.offsetTop, offsetY, element);

      return element.offsetTop - offsetY;
    }
  }
  return null;
};

export const shouldUpdateScroll = (props) => {
  if (typeof window === "undefined") {
    return;
  }
  const isMobile = window.innerWidth < 1366;

  if (!isMobile) {
    return false;
  }
  // console.log(props.routerProps.location);

  if (props.routerProps.location.state) {
    if (props.routerProps.location.state.scrollPosition) {
      // console.log("logging");

      return [0, props.routerProps.location.state.scrollPosition];
    }
    if (props.routerProps.location.state.modal) {
      // console.log("activate state modal scroll");

      return [0, window.pageYOffset];
    }
  }
  if (
    props.routerProps.location.hash !== ""
    //  && props.routerProps.location.key !== "initial"
  ) {
    const offset = getTargetOffset(props.routerProps.location.hash);
    // console.log(offset);

    if (offset !== null) {
      return [0, offset];
    }
  } else if (props.routerProps.location.key === "initial") {
    // console.log(props.routerProps.location.hash);
    return false;
    // return props.routerProps.location.hash;
  } else if (props.routerProps.location.key !== "initial") {
    // console.log(props);

    return [0, 0];
  }

  // if (props.routerProps.location.hash !== "" && isMobile) {
  //   console.log("dsa");

  //   return props.routerProps.location.hash.substr(1);
  // } else if (isMobile) {
  //   return [0, 0];
  // }
  return false;
  // return [0, 0];
};

// export const onRouteUpdate = ({ location }) => {
//   if (location && location.hash) {
//     const item = document.querySelector(`${location.hash}`);
//     if (item) {
//       console.log(item.offsetTop);

//       window.scrollTo({
//         top: item.offsetTop - 80,
//         behavior: "smooth",
//       });
//     }
//   }

//   return true;
// };

export const wrapPageElement = (args) => {
  return <NewPageWrap passProps={args.props} passElement={args.element} />;
};

export const wrapRootElement = (args) => {
  const { element } = args;
  Modal.setAppElement("#___gatsby");

  return <>{element}</>;
};
