// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-age-verification-js": () => import("./../../../src/pages/age-verification.js" /* webpackChunkName: "component---src-pages-age-verification-js" */),
  "component---src-pages-list-of-ingredients-js": () => import("./../../../src/pages/list-of-ingredients.js" /* webpackChunkName: "component---src-pages-list-of-ingredients-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-brand-js": () => import("./../../../src/templates/brand.js" /* webpackChunkName: "component---src-templates-brand-js" */),
  "component---src-templates-legal-page-js": () => import("./../../../src/templates/legal-page.js" /* webpackChunkName: "component---src-templates-legal-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-pdf-js": () => import("./../../../src/templates/pdf.js" /* webpackChunkName: "component---src-templates-pdf-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */)
}

